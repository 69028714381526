<template>
    <div class="box">
        <!-- 头部 -->
        <headers></headers>
        <el-carousel :interval="3000">
            <el-carousel-item>
                <img src="@/assets/homePage/关于盛泰banner.png" class="images">
            </el-carousel-item>
        </el-carousel>
        <!-- 内容 -->
        <div class="content">

            <h2 class="H2" v-if="$route.query.id == 1">{{ $route.query.name }}</h2>
        </div>
        <div class="content" style="flex-direction: row-reverse;">
            <div class="env" v-if="$route.query.id == 2">
                <span v-for="item in envList" @click="span_Btn(item.id)" :key="item.id"
                    :class="{ 'span_': item.id == meId }" style="overflow: hidden;">{{ item.name }}</span>
            </div>
        </div>
        <div class="content_img">
            <div class="box_img" v-for="item in empData" :key="item.id">
                <img :src="item.url">
                <p>{{ item.name }}</p>
            </div>
        </div>
        <!-- 底部 -->
        <foot></foot>
    </div>
    <div class="_div">
        <!-- 头部 -->
        <move_header></move_header>
        <!-- 轮播图 -->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item>
                <img src="@/assets/homePage/关于盛泰banner.png" style="width: 100%;">
            </van-swipe-item>
        </van-swipe>
        <!-- 内容 -->
       
             <h2  v-if="$route.query.id == 1">{{ $route.query.name }}</h2>
     
         <div class="env" v-if="$route.query.id == 2">
                <p class="h2_02" v-for="item in envList" @click="span_Btn(item.id)" :key="item.id"
                    :class="{ 'span_': item.id == meId }" style="overflow: hidden;">{{ item.name }}</p>
            </div>
        <div class="imgs" v-for="item in empData" :key="item.id">
            <img :src="item.url" alt="">
            <p>{{item.name}}</p>
        </div>
        
        <!-- 底部 -->
        <move_foot></move_foot>
    </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { reactive, toRefs, ref, onMounted, watch } from 'vue'
import { useRoute } from "vue-router";
export default {
    components: { foot, headers, move_foot, move_header },

    setup() {
        const route = useRoute()
        watch(() => route.query.id, (count, prevCount) => {
            // 当 state.count 更新，会触发此回调函数 
            methods.menuBtn(route.query.id)
        })
        const state = reactive({
            meId: localStorage.getItem('emp_id') || 2,
            envList: [
                { id: 1, name: '制造环境' },
                { id: 2, name: '制造设备' },
            ],
            empData: [

            ]
        })
        let methods = {
            span_Btn(id) {
                localStorage.setItem('emp_id', id)
                state.meId = id
                methods.menuBtn(route.query.id)
            },
            menuBtn(data) {
                let ID = localStorage.getItem('emp_id') || state.meId
                if (data == 1) {
                    state.empData = [
                        { id: 1, name: '设施完备的员工宿舍', url: require('@/assets/about/live/1.jpg') },
                        { id: 2, name: '设施完备的员工宿舍', url: require('@/assets/about/live/2.jpg') },
                        { id: 3, name: '设施完备的员工宿舍', url: require('@/assets/about/live/3.jpg') },
                        { id: 4, name: '优美的景观湖', url: require('@/assets/about/live/4.jpg') },
                        { id: 5, name: '员工活动中心', url: require('@/assets/about/live/5.jpg') },
                        { id: 6, name: '员工停车场', url: require('@/assets/about/live/6.jpg') }
                    ]
                } else if (data == 2 && ID == 1) {
                    state.empData = [
                        // { id: 1, name: '车载模组生产环境', url: require('@/assets/about/manufacture/制造环境/1.png') },
                        // { id: 2, name: '车载模组生产环境', url: require('@/assets/about/manufacture/制造环境/2.png') },
                        // { id: 3, name: '手机模组生产环境', url: require('@/assets/about/manufacture/制造环境/3.png') },
                        // { id: 4, name: '手机模组生产环境', url: require('@/assets/about/manufacture/制造环境/4.png') },
                        // { id: 5, name: 'NB产品生产环境', url: require('@/assets/about/manufacture/制造环境/5.png') },
                        // { id: 6, name: 'NB产品生产环境', url: require('@/assets/about/manufacture/制造环境/6.png') }
                    ]
                } else if (data == 2 && ID == 2) {
                    state.empData = [
                        // { id: 1, name: '车载模组生产环境', url: require('@/assets/about/manufacture/制造设备/1.png') },
                        // { id: 2, name: '车载模组生产环境', url: require('@/assets/about/manufacture/制造设备/2.png') },
                        // { id: 3, name: '手机模组生产环境', url: require('@/assets/about/manufacture/制造设备/3.png') },
                        // { id: 4, name: '手机模组生产环境', url: require('@/assets/about/manufacture/制造设备/4.png') },
                        // { id: 5, name: 'NB产品生产环境', url: require('@/assets/about/manufacture/制造设备/5.png') },
                        // { id: 6, name: 'NB产品生产环境', url: require('@/assets/about/manufacture/制造设备/6.png') }
                    ]
                }
            }
        }
        onMounted(() => {
            methods.menuBtn(route.query.id)
        })
        return {
            ...toRefs(state), ...methods
        }
    }
}
</script>

<style scoped lang="less">
.box {
    width: 100%;

    .content_img {
        display: flex;
        margin: 0px auto;
        max-width: 1920px;
        width: 70%;
        flex-wrap: wrap;

        .box_img {
            max-width: 50%;
            min-width: 50%;
            box-sizing: border-box;
            padding: 50px;
            flex: 1;
            text-align: center;

            img {
                width: 100%;
                border-radius: 8px;
                overflow: hidden;
            }
        }
    }

    .content {
        display: flex;
        margin: 0 auto;
        max-width: 1920px;
        width: 65%;
        position: relative;

        // 
        .H2::before {
            content: "";
            width: 30%;
            height: 2px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 40px;
            left: 60px;
            transform: translateX(-50%);
            // top:55px;
            min-width: 35px;
            max-width: 35px;
        }

        .H2 {
            font-size: 1.7rem;
        }

        .env {
            // position: absolute;
            right: 30px;
            font-size: 16px;
            top: 30px;
            margin-top: 30px;

            span {
                display: inline-block;
                width: 95px;
                height: 30px;
                // border-bottom: 2px solid #ef3e2e;
                text-align: center;
                cursor: pointer;
                box-sizing: border-box;
            }

            .span_ {
                border-bottom: 2px solid #ef3e2e;
            }
        }
    }
}

::v-deep .el-carousel {
    max-width: 1920px;
    margin: 0 auto;
    overflow-y: hidden;
    height: auto;
    max-height: 600px;

}

::v-deep .el-carousel__container {
    height: 100% !important;
    min-height: 500px;
}

.el-carousel {
    max-width: 1920px;
    margin: 0 auto;
}

::v-deep .el-card {
    border-radius: 8px;
}

.images {
    width: 100%;
    height: auto;
    max-height: 600px
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
    .box {
        display: block;
    }

    ._div {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .box {
        display: none;
    }

    ._div {
        display: block;
        // text-align: center;
        .env{
            text-align: center;
        }
      .span_ {
          border-bottom: 8px solid #ef3e2e;
                
            }
        .h2_02{
            display: inline-block;
            font-size: 70px;
            font-weight: bold;
            margin: 50px;
            height: 100px;
            box-sizing: border-box;
        }
        h2 {
            text-align: center;
            font-size: 80px;
        }
        h2::before {
            content: "";
            width: 30%;
            height: 8px;
            background-color: #c7000b;
            position: absolute;
            margin-top: 100px;
            left: 50%;
            transform: translateX(-50%);
            min-width: 100px;
            max-width: 100px;
        }
        .imgs{
            padding: 30px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 15px;
                overflow: hidden;
            }
            p{
                font-size: 60px;
                text-align: center;
            }
        }
    }
}
</style>